export default [
  {
    key: 'increasement',
    sortable: false,
    sortField: '',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'accountName',
    sortable: false,
    sortField: 'account_name',
    label: 'field.account_name',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'wholeSaleName',
    sortable: false,
    sortField: 'wholeSaleName',
    label: 'field.wholeSale',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'receiptUrl',
    sortable: false,
    sortField: '',
    label: 'field.receipt',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'diamondAmount',
    sortable: false,
    sortField: 'amount',
    label: 'field.amount_diamond',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'price',
    sortable: false,
    sortField: 'price',
    label: 'field.price',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'orderType',
    sortable: false,
    label: 'field.order_type',
    variant: 'light',
  },

  {
    key: 'isApprove',
    sortable: false,
    sortField: '',
    label: 'field.isApprove',
  },

  {
    key: 'actions',
    label: 'general.action',
  },
]
